import { useEffect, useState } from "react";
import './countdowntimer.css'
import { timerExerciseAtom } from "recoil/exercisesAtom";
import { useRecoilValue } from "recoil";
import { orientationAtom } from "recoil/OrientationAtom";
import { IonButtons, IonIcon, useIonRouter } from "@ionic/react";
import { arrowBackOutline, closeOutline } from "ionicons/icons";

interface ICountDownTimer { 
    minutes: number
}

export const CountDownTimer: React.FC <ICountDownTimer>= ({ minutes })=>{ 

    const [time, setTime] = useState(minutes * 60);
    const isRunning = useRecoilValue(timerExerciseAtom)
    const orientation = useRecoilValue(orientationAtom)

    const navigation = useIonRouter()

  useEffect(() => {
    if (isRunning && time > 0) {
      const timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [time, isRunning]);

  const minutesRemaining = Math.floor(time / 60);
  const secondsRemaining = time % 60;

    return(
        <> 
        <div className="cdtTimer-container" style={{ marginLeft: orientation === 'portrait-primary' ? '10px' : '80px' }}>
            <p style={{ fontSize:19, fontWeight:700 }}>
                {minutesRemaining.toString().padStart(2, '0')}:
                {secondsRemaining.toString().padStart(2, '0')}
            </p>
          </div>
        <div className="back-container" style={{ marginLeft: orientation === 'portrait-primary' ? '10px' : '80px', cursor: 'pointer' }} onClick={()=> {
                navigation.push('/quick-training', 'back' , 'replace')
                }}>
          <IonButtons style={{ width:24, height:24, cursor: 'pointer' }} slot="start" onClick={()=> {
                navigation.push('/quick-training', 'back' , 'replace')
                }}>
                <IonIcon style={{ width:24, height:24 }} icon={arrowBackOutline} />
            </IonButtons>
          </div>       
          
        </>
    )
}