import { useEffect, useState } from "react";
import './countdownstart.css'
import { orientationAtom } from "recoil/OrientationAtom";
import { useRecoilValue } from "recoil";
import { IonButtons, IonIcon, useIonRouter } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";

interface ICountDownTimer { 
    seconds: number,
    onComplete: ()=>void
}

export const CountDownStart: React.FC <ICountDownTimer>= ({ seconds, onComplete })=>{ 

    const [time, setTime] = useState(seconds);
    const orientation = useRecoilValue(orientationAtom)
    const navigation = useIonRouter()

  useEffect(() => {
      const timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      if (time <= 0) {
        onComplete();
      }

      return () => {
        clearInterval(timer);
      };
      
  }, [time]);

    return(
        <>
          <div className="cdt-container" style={{ marginLeft: orientation === 'portrait-primary' ? '10px' : '80px' }}>
              <p style={{ fontSize:30, fontWeight:700 }}>
                  {time}
              </p>
          </div>
          <div className="back-container-start" style={{ marginLeft: orientation === 'portrait-primary' ? '10px' : '80px', cursor: "pointer" }} onClick={()=> {
                navigation.push('/quick-training', 'back' , 'replace')
                }}>
          <IonButtons style={{ cursor: 'pointer' }} slot="start" onClick={()=> {
                navigation.push('/quick-training', 'back' , 'replace')
                }}>
                <IonIcon style={{ width:24, height:24 }} icon={arrowBackOutline} />
            </IonButtons>
          </div>   
        </>
    )
}