import React, { useEffect, useRef, useState } from "react";

//react-router-dom
import { useParams } from "react-router";

//ionic
import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonText,
	IonGrid,
	useIonRouter,
	IonMenuButton,
} from "@ionic/react";

//icons
import { arrowBackOutline } from "ionicons/icons";
import { useGetExerciseById } from "hooks/aprendizaje/useGetExerciseById";

//css
import "./detalles-claves.css";

//hooks
import { usePostModule } from "hooks/module/usePostModule";
import useScreenSize from "hooks/screen-size/useScreenSize";
import { useGetUserById } from "hooks/users/useGetUserById";
import { useGetCoaches } from "hooks/aprendizaje/useGetCoaches";
import { useGetModuleExercises } from "hooks/aprendizaje/useGetModuleExercises";
import { usePostExerciseCompleted } from "hooks/aprendizaje/usePostExerciseCompleted";
import { useGetBasisExercises } from "hooks/entrenamiento-rapido/useGetBasisExercises";

//react-player
import ReactPlayer from "react-player";

//recoil
import { userInfoState } from "recoil/User";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { moduleFinishedAtom } from "recoil/moduleFinishedAtom";
import {
	exerciseFinishedAtom,
	multipleExerciseFinishedAtom,
} from "recoil/exerciseFinishedAtom";
import { orientationAtom } from "recoil/OrientationAtom";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation";
import { ratingModalAtom } from "recoil/ratingModalAtom";
import { useGetModuleByID } from "hooks/aprendizaje/useGetModuleByID";
import { useGetBasisById } from "hooks/aprendizaje/useGetBasisById";
import bienvenida from 'assets/Loading_huevo_2.gif'

interface IParams {
	idBasis: string;
	idModulo: string;
	idEjercicio: string;
}

interface Exercise {
	idExercise: number;
	exerciseName: string;
	videoURL: string;
	idModule: number;
	moduleName: string;
	idBasis: number;
	excluded: number;
	createdAt: number;
}

export const DetallesClave: React.FC = () => {
	const params: IParams = useParams();
	const { idBasis, idEjercicio, idModulo } = params;

	const user = useRecoilValue(userInfoState);
	const { idUser } = user;

	const navigation = useIonRouter();

	const { coaches, handleGetCoaches } = useGetCoaches();

	const { exercise, handleGetExerciseById } = useGetExerciseById();

	const { handlePostExerciseCompleted } = usePostExerciseCompleted();

	const { basis, handleGetBasisById } = useGetBasisById();
	const { ModuleById, handleGetModuleById } = useGetModuleByID();

	const { handleGetUserById } = useGetUserById();

	const setExerciseFinished = useSetRecoilState(exerciseFinishedAtom);
	const setMultipleExerciseFinished = useSetRecoilState(
		multipleExerciseFinishedAtom,
	);
	const setRatingModal = useSetRecoilState(ratingModalAtom);
	const setFinishedModule = useSetRecoilState(moduleFinishedAtom);
	const [orientation, setOrientation] = useRecoilState(orientationAtom);
	const [fallbackVideo, setFallbackVideo] = useState(false);

	const { moduleExercises, handleGetModuleExercises } =
		useGetModuleExercises();
	const { exercises, handleGetBasisExercises } = useGetBasisExercises();

	const { width } = useScreenSize();

	

	useEffect(() => {
		handleGetModuleExercises(Number(idBasis), Number(idModulo));
		handleGetBasisById(Number(idBasis));
		handleGetModuleById(Number(idBasis),Number(idModulo));
	}, [handleGetModuleExercises, idBasis, idModulo]);

	const lastExercise =
		moduleExercises && moduleExercises.length > 0
			? moduleExercises[moduleExercises.length - 1]
			: null;
	const lastExerciseId = lastExercise?.idExercise;

	const [exerciseExists, setExerciseExists] = useState<Exercise | undefined>(
		undefined,
	);
	useEffect(() => {
		const exerciseFound = exercises.find(
			(exercise: Exercise) => exercise.idExercise === Number(idEjercicio),
		);
		setExerciseExists(exerciseFound);
	}, [exercises, idEjercicio]);

	useEffect(() => {
		if (idEjercicio) {
			const parsed_exercise_id = parseInt(idEjercicio);
			handleGetExerciseById(parsed_exercise_id);
		}
	}, [handleGetExerciseById, idEjercicio]);

	useEffect(() => {
		if (idBasis) {
			const parsed_basis_id = parseInt(idBasis);
			handleGetBasisExercises(parsed_basis_id);
			handleGetCoaches(parsed_basis_id);
		}
	}, [handleGetCoaches, idBasis, handleGetBasisExercises]);

	useEffect(() => {
		if (idBasis) {
			const parsed_basis_id = parseInt(idBasis);
			handleGetBasisExercises(parsed_basis_id);
			handleGetCoaches(parsed_basis_id);
		}
	}, [handleGetCoaches, idBasis, handleGetBasisExercises]);

	const { name, videoURL, details, idExercise } = exercise;
	const { name:NameBasis } = basis;
	const [moduleName , setModuleName] = useState("");
	const [url , setUrl] = useState("");
	const [NameBasisCached , setNameBasisCached] = useState("");
	useEffect(() => {
		setTimeout(()=> {
			if(ModuleById && NameBasis){
				setUrl("https://basket-files-prod.s3.us-east-2.amazonaws.com/videosLocal/");
				setModuleName(ModuleById.name);
				setNameBasisCached(NameBasis);
			}
		},1000);
	},[NameBasis, ModuleById]);
	

	const module:any = exercises.find((e:any) => e.exerciseName === name);
	const playerRef2 = React.useRef<any>(null);

	const videoJsOptions = {
		autoplay: true,
		controls: true,
		responsive: true,
		fluid: true,
		sources: [{
		src: url,
		type: 'video/mp4'
		}]
	};

	const handlePlayerReady = (player: any) => {
		playerRef2.current = player;
	
		// You can handle player events here, for example:
		player.on('waiting', () => {
		 // videojs.log('player is waiting');
		});
	
		player.on('dispose', () => {
		  //videojs.log('player will dispose');
		});
	  };

	const playerRef = useRef<ReactPlayer | null>(null);

	const { handlePostModule } = usePostModule();

	const handleEnded = () => {
		if (idExercise && idUser) {
			handlePostExerciseCompleted(idUser, idExercise);
			handleGetUserById(idUser);
			setTimeout(() => {
				if(playerRef2 && playerRef2.current) {
					playerRef2.current.seekTo(0);
				}
				navigation.push(`/modulo/${idBasis}/${idModulo}`);
			}, 500);

			if (
				lastExerciseId === Number(idEjercicio) &&
				lastExercise?.completed === 0
			) {
				setFinishedModule(true);
				setRatingModal(true);
				setExerciseFinished(false);
				handlePostModule(idUser, Number(idModulo));
			} else {
				if (exerciseExists) setMultipleExerciseFinished(true);
				else setExerciseFinished(true);
			}
			const parsed_basis_id = parseInt(idBasis);
			handleGetBasisExercises(parsed_basis_id);
		}

		const player = name !== "Paso retirada hacia afuera" ? playerRef.current : playerRef2.current;
		if (player && document.fullscreenElement) {
			document.exitFullscreen();
		}
	};

	useEffect(() => {
		const handleOrientationChange = () => {
			setOrientation(ScreenOrientation.type);
		};
		window.addEventListener("orientationchange", handleOrientationChange);

		return () => {
			window.removeEventListener(
				"orientationchange",
				handleOrientationChange,
			);
		};
	}, [ScreenOrientation.type]);

	const [playerHeight, setPlayerHeight] = useState(0);
	const playerContainerRef = useRef<HTMLDivElement>(null);
	const [playerContainerWidth, setPlayerContainerWidth] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			if (
				playerContainerRef.current &&
				playerContainerRef.current.offsetWidth
			) {
				const newWidth = playerContainerRef.current.offsetWidth;
				setPlayerContainerWidth(newWidth);
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		setPlayerHeight(playerContainerWidth / (16 / 9));
	}, [playerContainerWidth]);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<div className="f-header-container">
						<IonButtons slot="start">
							<IonBackButton
								className="ioBackButton"
								icon={arrowBackOutline}
								color="dark"
								text={""}
								defaultHref="/tabs"
							/>
						</IonButtons>
						<IonText className="f-header-title">{name}</IonText>
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonGrid className="dc-container">
					<div
						style={{
							width: "100%",
							height: "100%",
							position: "relative",
						}}
						ref={playerContainerRef}
					>
						{!!url && !!NameBasisCached && !!moduleName && !!name ? (
							<>
              <ReactPlayer 
               className="video-training-player"
               ref={playerRef2}
               playing={true}
               controls={true} 
               url={url + NameBasisCached?.replace(/[\s_-]+/g, "+").toLocaleLowerCase() + "/" + moduleName?.replace(/[\s_-]+/g, "+")
                 .replace(/^-+|-+$/g, "")
                 .normalize("NFD")
                 .replace(/[\u0300-\u036f]/g, "") + "/mobile/" + name.toLowerCase()
                 .normalize("NFD")
                 .replace(/[\u0300-\u036f]/g, "").replace(/[\s]+/g, "+").replace(/["\s]+/g, "") + ".mp4"}
               width='100%'
               playsinline
               height={orientation === 'portrait-primary' ? '220px' : '60%'}
               onContextMenu={(e:any) => e.preventDefault() }
               config={{
                 file:{
                   attributes: { controlsList: 'nodownload' }
                 }
               }}
               onEnded={handleEnded}
               />
              </>
						) : (
						 <img className="loader-dc" src={bienvenida} />
						)}
					</div>
					<IonGrid className="dc-text-container">
						<IonText className="dc-title">Detalles claves</IonText>
						<div className="dc-container">
							<div
								className="dc-list-item"
								dangerouslySetInnerHTML={{ __html: details }}
							></div>
						</div>
						<div className="dc-trainer-container">
							{coaches &&
								coaches.length !== 0 &&
								coaches.map((coach, index) => {
									const { fullName } = coach;
									return (
										<IonText key={index} className="dc-trainer-name">
											{fullName}
										</IonText>
									);
								})}
						</div>
					</IonGrid>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};
